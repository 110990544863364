<template>
  <v-container
    class="ma-0 pa-3"
    style="overflow: hidden;"
  >
    <div :class="description? 'zoom text-decoration-underline' : ''">
      <v-hover v-slot="{ hover }">
        <span
          :style="getStyle(description, hover)"
          @click="description_show = !description_show"
        >{{ name }}</span>
      </v-hover>
    </div>

    <v-row
      no-gutters
      v-show="description_show && description"
    >
      <v-col
        align="left"
        class="text-body-2"
      >
        <span v-html="description"></span>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
export default {
  props: {
    name: { type: String, required: true },
    description: { type: String, required: false },
  },

  data() {
    return {
      description_show: false,
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {

    getStyle(description, hover) {
      let tmp = '';
      if (description)
        tmp += 'cursor: pointer;';

      return tmp;
    }
  }
};
  </script>

  <style scoped>
.zoom:hover {
  animation: pulse 1.1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.15);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(1);
  }
}
</style>