<template>
  <v-container class="ma-0 pa-0">
    <v-row
      no-gutters
      align-self="start"
      v-show="description"
    >
      <v-col align="center">
        <v-btn
          text
          small
          @click="description_show = !description_show"
          color="primary"
        >{{ name }}</v-btn>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-show="description_show"
    >
      <v-col>
        <span v-html="description"></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    description: { type: String, required: true },
  },

  data() {
    return {
      description_show: false,
    }
  },

  events: {},

  watch: {
  },

  computed: {
  },

  mounted: function () {
  },

  methods: {
  }
};
</script>