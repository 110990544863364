<template>
  <v-container class="ma-0 pa-0">
    <v-btn
      x-small
      text
      color="primary"
      @click="show = !show"
    >
      Podrobnosti
    </v-btn>

    <div v-show="show">
      <v-row no-gutters>
        <v-col
          cols="12"
          class="text-body-2"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Spolu hlasovali</td>
                  <td>{{ question.votes_total_count }}</td>
                </tr>
                <tr>
                  <td>Nehlasovali</td>
                  <td>{{ question.not_voted_count }}</td>
                </tr>
                <tr>
                  <td> <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          top
                          dark
                          v-bind="attrs"
                          v-on="on"
                          size="15"
                          style="margin-bottom: 1.5px;"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <v-card><v-card-text> <span>V reálnej skutočnosti je toto číslo počet oprávnených voličov.</span>

                        </v-card-text></v-card>
                    </v-menu>
                    Počet zaregistrovaných potenciálnych hlasujúcich</td>
                  <td>{{ question.possible_voters_count }}</td>
                </tr>
                <tr>
                  <td>Dátum a čas vytvorenia</td>
                  <td>{{ question.created_at | moment("DD.MM.YYYY HH:mm") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
      
      
      <script>

export default {

  name: "PollQuestionDetail",
  props: {
    question: { type: Object, required: true },
  },

  components: {
  },

  data: () => ({
    show: false,
  }),

  mounted: function () {
  },

  methods: {
  }
};
      </script>